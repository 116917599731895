.cursor-pointer {
  cursor: pointer;
}

.dropdown-menu {
  z-index: 9999;
}

/* LOADER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}

/* CALENDAR */

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  border-top: none;
}

.rbc-time-content {
  border: none;
}

.rbc-time-header.rbc-overflowing {
  border: none;
}

.rbc-header + .rbc-header {
  border: none;
}

.rbc-time-view {
  border: none;
}

.rbc-time-header-content {
  border-left: none;
}

.rbc-header {
  border-bottom: none;
}

.rbc-timeslot-group {
  border-bottom: none;
  height: 55px;
}

.rbc-label {
  font-family: 'Lato';
  font-size: 10px;
  color: #8181a5;
}

.rbc-day-slot .rbc-timeslot-group {
  border-bottom: 1px solid #d8d8d8;
}

.rbc-timeslot-group .rbc-time-slot {
  border-top: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-today {
  background-color: transparent;
}

.rbc-event-label {
  display: none;
}

.rbc-event {
  background-color: transparent;
  border: none;
  padding: 0;
}

.rbc-day-slot .rbc-event {
  border: none;
}

.rbc-date-cell {
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: #343434;
}

.rbc-date-cell a {
  cursor: default;
}

.rbc-event.rbc-selected {
  background-color: unset;
  border: 1px solid #4eba7e;
}

.rbc-event:focus {
  outline: none;
}

.rbc-current-time-indicator {
  height: 3px;
  background-color: #fe4164;
}

/* Jira Service Management (JSM) Help Widget */
@media (max-width: 599px) {
  iframe[name='JSD widget'] {
    display: block;
    margin-bottom: 60px;
  }
}

/* Jira Service Management (JSM) Issue Collector */
.atlwdg-trigger.atlwdg-RIGHT {
  background: #4EBA7E;
}

@media (max-width: 599px) {
  /* hide on mobile */
  .atlwdg-trigger.atlwdg-RIGHT {
    display: none;
  }
}
